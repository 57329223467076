import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

const PageNotFound = ({ updateBreadcrumbTitle }) => {
  useEffect(() => {
    updateBreadcrumbTitle("Lost In Space!!!");
  }, []);
  //updateBreadcrumbTitle("Not Found");
  return (
    <Fragment>
      <div className="col s12 center-align white">
        <img
          src={process.env.PUBLIC_URL + "/img/error-2.png"}
          alt=""
          className="width100p"
        />
        <h1 className="error-code m-0">404</h1>
        <h6 className="mb-2">Page Not Found!</h6>
        <Link
          to="/"
          className="btn waves-effect waves-light gradient-45deg-deep-purple-blue gradient-shadow mb-4"
        >
          Back To Home
        </Link>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
