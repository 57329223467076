export const currentEnv = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "LOCAL";

export const apiBaseUrl = () => {
  const urls = {
    LOCAL: "http://dealer.local.com",
    QA: "https://admin.qac24svc.dev",
    STAGING: "https://admin-staging.c24svc.app",
    PRODUCTION: "https://admin.24c.in"
  };
  return urls[currentEnv];
};

export const taxationApiBaseUrl = () => {
  const urls = {
    LOCAL: "http://localhost:8181",
    QA: "https://oms-taxation.qac24svc.dev",
    STAGING: "https://stage-oms-taxation.qac24svc.dev",
    PRODUCTION: "https://oms-taxation-service.cars24.team"
  };
  return urls[currentEnv];
};
