import React, { useEffect, useState, Fragment } from "react";
import Select from "../common/select";
import { If } from "react-if";
import { getChargesList } from "../../services/eav";
import { toast } from "react-toastify";
import {
  DEFAULT_CHARGES_REGION_ID,
  ACTUAL_CHARGES_ROOT_REGION_ID
} from "../../constants/constants";

const AddRegionCharge = ({
  addRegionChargeConnect,
  regionsList,
  fetchAllRegionsListConnect,
  updateBreadcrumbTitleConnect
}) => {
  const [form, setForm] = useState({
    regionId: "",
    charges: []
  });

  useEffect(() => {
    updateBreadcrumbTitleConnect("Add Region Charge");
    fetchAllRegionsListConnect();
  }, []);

  const chargesKey = "charges";
  const regionsListArray = regionsList.map(row => {
    //default cars24 root region id is assumed 0 in older code
    return {
      key:
        row.id === ACTUAL_CHARGES_ROOT_REGION_ID
          ? DEFAULT_CHARGES_REGION_ID
          : row.id,
      value: row.value
    };
  });

  // Handle form data change event
  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === chargesKey) {
      if (target.checked === true) {
        form[chargesKey].push(value);
      } else {
        form[chargesKey].splice(form[chargesKey].indexOf(value), 1);
      }
    } else {
      form[name] = value;
    }
    setForm({ ...form });
  };

  const chargesListHtml = getChargesList().map(row => {
    return (
      <p key={row.key}>
        <label>
          <input
            name="charges"
            type="checkbox"
            className="filled-in"
            value={row.key}
            onChange={handleInputChange}
          />
          <span>{row.value}</span>
        </label>
      </p>
    );
  });

  //Validate form
  const validateForm = () => {
    if (!form["regionId"]) {
      toast.error("Region cannot be empty!");
      return false;
    }
    if (form.charges.length == 0) {
      toast.error("Atleast one charge must be selected!");
      return false;
    }
    return true;
  };

  //Handle Form Submit
  const handleFormSubmit = event => {
    try {
      event.preventDefault();
      if (!validateForm()) {
        return false;
      }
      addRegionChargeConnect(form).then(response => {
        toast.success("Data updated successfully");
      });
    } catch {}
  };

  return (
    <Fragment>
      <form onSubmit={e => handleFormSubmit(e)}>
        <div className="row">
          <div className="col l12 s12 m12">
            <h5>Add Region Charges</h5>
          </div>
          <div className="col l5 m5 s5">
            <If condition={regionsList.length > 0}>
              <Select
                name="regionId"
                options={regionsListArray}
                handleInputChange={handleInputChange}
                searchable={true}
              ></Select>
            </If>
          </div>
          <div className="clearfix"></div>
          <div className="col l5 m5 s5">
            <h5>Select Charges</h5>
            {chargesListHtml}
          </div>
          <div className="clearfix"></div>
          <div className="col l3 m3 s3">
            <br />
            <input type="submit" className="btn green" value="Submit" />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AddRegionCharge;
