import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { appError: false };
  }

  componentDidCatch(error, info) {
    console.log("%c" + error, "background: #222; color: #bada55");
    // Display fallback UI
    this.setState({ appError: true });
  }

  render() {
    if (this.state.appError) {
      // You can render any custom fallback UI
      return (
        <div className="opsBox">
          <div className="alert alert-danger alert-dismissible">
            <h4>
              <i className="icon fa fa-ban" /> Alert!
            </h4>
            Error: An error occured while rendering components. Please reload
            the page!
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
