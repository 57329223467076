import Types from "./types";
import { TaxationApi } from "../../services";

export const updateRegionChargeInit = () => {
  return { type: Types.UPDATE_REGION_CHARGE_INIT };
};

export const updateRegionChargeSuccess = data => {
  return { type: Types.UPDATE_REGION_CHARGE_SUCCESS, data };
};

export const updateRegionChargeFailure = () => {
  return { type: Types.UPDATE_REGION_CHARGE_FAILURE };
};

export const updateRegionCharge = data => dispatch => {
  dispatch(updateRegionChargeInit());
  return new Promise((resolve, reject) => {
    TaxationApi.updateRegionCharge(data)
      .then(response => {
        dispatch(updateRegionChargeSuccess(response));
        resolve(response.data);
      })
      .catch(error => {
        dispatch(updateRegionChargeFailure());
        reject(error);
      });
  });
};
