import React, { Fragment, useEffect } from "react";

const Header = () => {
  useEffect(() => {
    initializeElements();
  }, []);

  return (
    <Fragment>
      <header className="page-topbar" id="header">
        <div className="navbar">
          <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-purple no-shadow">
            <div className="nav-wrapper">
              <ul className="navbar-list right">
                {/* Logout li starts here */}
                <li>
                  <a
                    className="waves-effect waves-block waves-light profile-button"
                    href="#"
                    data-target="profile-dropdown"
                  >
                    <i className="material-icons">account_circle</i>
                  </a>
                  <ul className="dropdown-content" id="profile-dropdown">
                    <li>
                      <a className="grey-text text-darken-1" href="/logout">
                        <i className="material-icons">keyboard_tab</i> Logout
                      </a>
                    </li>
                  </ul>
                </li>
                {/* Logout li ends here */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </Fragment>
  );
};

const initializeElements = () => {
  const M = window.M;
  let elems = document.querySelectorAll(".profile-button");
  M.Dropdown.init(elems, { coverTrigger: false, constrainWidth: false });
};

export default Header;
