import React, { Fragment } from "react";

/**
 * elements (Array of object) json should contain [{key,icon,headerTitle,body}]
 */
const Collapsible = ({ elements }) => {
  let collapsibleHtml = null;
  if (elements) {
    collapsibleHtml = elements.map(row => {
      return (
        <li key={row.key ? row.key : ""}>
          <div className={`collapsible-header ${row.headerClass}`}>
            <i className="material-icons">{row.icon ? row.icon : ""}</i>
            <b>{row.headerTitle ? row.headerTitle : ""}</b>
          </div>
          <div className="collapsible-body">{row.body ? row.body : ""}</div>
        </li>
      );
    });
  }

  return (
    <Fragment>
      <ul className="collapsible popout">{collapsibleHtml}</ul>
    </Fragment>
  );
};

export default Collapsible;
