import Types from "./types";
import { createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  data: {},
  isLoading: false
};

export const addRegionChargeInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const addRegionChargeSuccess = (state = INITIAL_STATE, data) => {
  return { ...state, ...data, isLoading: false };
};

export const addRegionChargeFailure = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.ADD_REGION_CHARGE_INIT]: addRegionChargeInit,
  [Types.ADD_REGION_CHARGE_SUCCESS]: addRegionChargeSuccess,
  [Types.ADD_REGION_CHARGE_FAILURE]: addRegionChargeFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
