import Types from "./types";
import { RegionApi, OmsRegionApi } from "../../services";

const fetchRegionsListInit = () => {
  return {
    type: Types.FETCH_REGIONS_LIST_INIT
  };
};

const fetchRegionsListSuccess = data => {
  return {
    type: Types.FETCH_REGIONS_LIST_SUCCESS,
    data
  };
};

const fetchRegionsListFailure = () => {
  return {
    type: Types.FETCH_REGIONS_LIST_FAILURE
  };
};

const fetchRegionsList = params => dispatch => {
  dispatch(fetchRegionsListInit());
  return new Promise((resolve, reject) => {
    RegionApi.getRegionsList(params)
      .then(response => {
        dispatch(fetchRegionsListSuccess(response));
        resolve(response.data.data);
      })
      .catch(error => {
        dispatch(fetchRegionsListFailure());
        reject(error);
      });
  });
};

//Fetch All regions
const fetchAllRegionsList = params => dispatch => {
  dispatch(fetchRegionsListInit());
  return new Promise((resolve, reject) => {
    OmsRegionApi.getAllRegionsList(params)
      .then(response => {
        dispatch(fetchRegionsListSuccess(response));
        resolve(response.data.detail);
      })
      .catch(error => {
        dispatch(fetchRegionsListFailure());
        reject(error);
      });
  });
};

export { fetchRegionsList, fetchAllRegionsList };
