import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Header from "./components/common/header";
import LeftSideBar from "./components/common/leftSidebar";
import Footer from "./components/common/footer";
import AppRouter from "./components/common/appRouter";
import ErrorBoundary from "./components/common/errorBoundary";
import Loader from "./components/common/loader";
import { authenticate } from "./utils/helpers/authentication";

const App = () => {
  authenticate();
  return (
    <Router>
      <Loader />
      <Header />
      <LeftSideBar />
      <ErrorBoundary>
        <AppRouter />
        <ToastContainer />
      </ErrorBoundary>
      <Footer />
    </Router>
  );
};

export default App;
