import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

class MainComponent extends Component {
  //Render main component
  render() {
    //Render main components when logged-in
    return (
      <Provider store={store}>
        <Fragment>
          <App />
        </Fragment>
      </Provider>
    );
  }
}

ReactDOM.render(<MainComponent />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
