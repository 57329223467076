import React, { Fragment, useEffect, useState } from "react";
import { If, Else, Then } from "react-if";
import Select from "../common/select";
import { toast } from "react-toastify";
import {
  getChargeAmountTypes,
  getChargeModes,
  getchargeTypeConstants
} from "../../services/eav";
import _ from "underscore";
import { DEFAULT_CHARGES_REGION_ID } from "../../constants/constants";

const EditRegionCharge = ({
  chargeDetail,
  updateRegionChargeConnect,
  fetchRegionChargesConnect
}) => {
  let clonedChargeDetail = JSON.parse(JSON.stringify(chargeDetail));
  const additionalData = {
    id: clonedChargeDetail.id,
    name: clonedChargeDetail.name || null,
    chargeKey: clonedChargeDetail.chargeKey
      ? clonedChargeDetail.chargeKey
      : null,
    chargeMode: clonedChargeDetail.chargeMode
      ? clonedChargeDetail.chargeMode
      : null,
    chargeType: clonedChargeDetail.chargeType
      ? clonedChargeDetail.chargeType
      : null,
    regionId: clonedChargeDetail.regionId ? clonedChargeDetail.regionId : null,
    cgst: clonedChargeDetail.cgst ? clonedChargeDetail.cgst : 0,
    sgst: clonedChargeDetail.sgst ? clonedChargeDetail.sgst : 0,
    igst: clonedChargeDetail.igst ? clonedChargeDetail.igst : 0,
    isActive: clonedChargeDetail.isActive ? clonedChargeDetail.isActive : null,
    chargeType: clonedChargeDetail.chargeType
      ? clonedChargeDetail.chargeType
      : null,
    //disableSensitiveFields: clonedChargeDetail.chargeType ? true : false,
    disableSensitiveFields: false,
    disableMinRange: true
  };

  let [slabData, setSlabData] = useState(clonedChargeDetail.charges || []);
  const [chargesAdditionalData, setChargesAdditionalData] = useState(
    additionalData
  );

  const randomIdPrefix = "RANDOM-";
  let initialChargeSlab = {
    id: `${randomIdPrefix}${Date.now()}`,
    rangeStart: null,
    rangeEnd: null,
    amount: null,
    amountType: null
  };

  //Handle add charge button click event
  const handleAddCharge = event => {
    let clonedChargesAdditionalData = { ...chargesAdditionalData };
    if (slabData.length >= 1) {
      if (slabData[slabData.length - 1]["rangeEnd"]) {
        let rangeEnd = slabData[slabData.length - 1]["rangeEnd"];
        initialChargeSlab.rangeStart = parseInt(rangeEnd) + 1;
        clonedChargesAdditionalData.disableMinRange = true;
      } else {
        toast.error("Add previous slab max value before adding new slab");
        return false;
      }
    } else {
      clonedChargesAdditionalData.disableMinRange = false;
    }
    setChargesAdditionalData(clonedChargesAdditionalData);
    slabData = [...slabData, initialChargeSlab];
    setSlabData(slabData);
  };

  //Handle delete all charge
  const deleteAllSlabs = event => {
    setSlabData([]);
  };

  //Handle delete charge button click event
  const handleDeleteCharge = (event, index) => {
    slabData.splice(index, 1);
    setSlabData([...slabData]);
  };

  // Handle addtional data change event
  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    chargesAdditionalData[name] = value;
    setChargesAdditionalData({ ...chargesAdditionalData });
  };

  // Handle slab change event
  const handleChargeSlabChange = (event, id) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    _.findWhere(slabData, { id: id })[name] = value;
    setSlabData([...slabData]);
  };

  // Handle form submit event
  const handleFormSubmit = async event => {
    try {
      event.preventDefault();
      let parsedSlabData = [];
      slabData = slabData.map(row => {
        if (
          typeof row["id"] === "string" &&
          row["id"].includes(`${randomIdPrefix}`)
        ) {
          row.id = 0;
        }
        return row;
      });
      parsedSlabData["charges"] = slabData;
      let formData = { ...chargesAdditionalData, ...parsedSlabData };
      await updateRegionChargeConnect(formData);
      await fetchRegionChargesConnect({
        regionId: [DEFAULT_CHARGES_REGION_ID, clonedChargeDetail.regionId].join(
          ","
        )
      });
      toast.success("Data updated successfully");
    } catch (Exception) {
      console.log(Exception);
    }
  };

  return (
    <Fragment>
      <form onSubmit={e => handleFormSubmit(e)}>
        <div className="row">
          <fieldset
            disabled={
              !(
                clonedChargeDetail &&
                clonedChargeDetail.regionId !== DEFAULT_CHARGES_REGION_ID
              )
            }
          >
            <div className="input-field col l6 m6 s6">
              <div className="switch">
                <h6>Charge State:</h6>
                <label>
                  In-Active
                  <input
                    name="isActive"
                    type="checkbox"
                    checked={chargesAdditionalData.isActive}
                    onChange={e =>
                      handleInputChange(e, chargesAdditionalData.id)
                    }
                  />
                  <span className="lever"></span>
                  Active
                </label>
              </div>
            </div>

            <If condition={!chargesAdditionalData.disableSensitiveFields}>
              <div className="input-field col l6 m6 s6">
                <div className="col l5 m5 s5">
                  <button
                    type="button"
                    className="btn waves-effect waves-light blue"
                    onClick={handleAddCharge}
                  >
                    Add Slab
                  </button>
                </div>
                <div className="col l7 m7 s7">
                  <button
                    type="button"
                    className="btn waves-effect waves-light red"
                    onClick={deleteAllSlabs}
                  >
                    Delete Slabs
                  </button>
                </div>
              </div>
            </If>
            <div className="clearfix"></div>

            <div className="input-field col l6 m6 s12">
              <Select
                label={"Charge Mode"}
                name="chargeMode"
                options={getChargeModes()}
                defaultValue={chargesAdditionalData.chargeMode}
                handleInputChange={handleInputChange}
              ></Select>
            </div>

            <div className="input-field col l6 m6 s12">
              <Select
                label={"Charge Type"}
                name="chargeType"
                options={getchargeTypeConstants()}
                handleInputChange={handleInputChange}
                defaultValue={chargesAdditionalData.chargeType}
                disabled={chargesAdditionalData.disableSensitiveFields}
              ></Select>
            </div>

            <div className="input-field col l4 m4 s12">
              <label className="active">Igst</label>
              <input
                id="igst"
                name="igst"
                placeholder="Igst"
                type="number"
                value={chargesAdditionalData.igst}
                required
                onChange={e => handleInputChange(e, chargesAdditionalData.id)}
              />
            </div>

            <div className="input-field col l4 m4 s12">
              <label className="active">Cgst</label>
              <input
                id="cgst"
                name="cgst"
                placeholder="Cgst"
                type="number"
                value={chargesAdditionalData.cgst}
                required
                onChange={e => handleInputChange(e, chargesAdditionalData.id)}
              />
            </div>

            <div className="input-field col l4 m4 s12">
              <label className="active">Sgst</label>
              <input
                id="sgst"
                name="sgst"
                placeholder="sgst"
                type="number"
                value={chargesAdditionalData.sgst}
                required
                onChange={e => handleInputChange(e, chargesAdditionalData.id)}
              />
            </div>

            <div className="col l12 s12 m12">
              <table>
                <thead>
                  <tr>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Type</th>
                    <th>Value</th>
                    <If
                      condition={!chargesAdditionalData.disableSensitiveFields}
                    >
                      <th>Action</th>
                    </If>
                  </tr>
                </thead>

                <tbody>
                  <If condition={slabData}>
                    <Else>-</Else>
                    <Then>
                      {slabData.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td>
                              <input
                                name="rangeStart"
                                placeholder="Min"
                                type="text"
                                value={row.rangeStart}
                                required
                                onChange={e =>
                                  handleChargeSlabChange(e, row.id)
                                }
                                disabled={chargesAdditionalData.disableMinRange}
                              />
                            </td>
                            <td>
                              <input
                                name="rangeEnd"
                                placeholder="Max"
                                type="text"
                                value={row.rangeEnd}
                                required
                                onChange={e =>
                                  handleChargeSlabChange(e, row.id)
                                }
                                disabled={
                                  chargesAdditionalData.disableSensitiveFields
                                }
                              />
                            </td>
                            <td>
                              <Select
                                options={getChargeAmountTypes()}
                                defaultValue={row.amountType}
                                name="amountType"
                                handleInputChange={handleChargeSlabChange}
                                fieldIdentifier={row.id}
                              ></Select>
                            </td>
                            <td>
                              <input
                                name="amount"
                                placeholder="Value"
                                type="number"
                                value={row.amount}
                                onChange={e =>
                                  handleChargeSlabChange(e, row.id)
                                }
                                required
                              />
                            </td>
                            <If
                              condition={
                                !chargesAdditionalData.disableSensitiveFields
                              }
                            >
                              <td>
                                <button
                                  type="button"
                                  className="btn red btn-small"
                                  onClick={e => handleDeleteCharge(e, index)}
                                >
                                  <i className="material-icons">delete</i>
                                </button>
                              </td>
                            </If>
                          </tr>
                        );
                      })}
                    </Then>
                  </If>
                </tbody>
              </table>
            </div>
          </fieldset>
          {/* Conditional save button */}
          <If
            condition={
              clonedChargeDetail &&
              clonedChargeDetail.regionId !== DEFAULT_CHARGES_REGION_ID
            }
          >
            <Then>
              <div className="className col l2 right">
                <button
                  type="submit"
                  className="btn waves-effect waves-light green"
                >
                  Save
                </button>
              </div>
            </Then>
          </If>
        </div>
      </form>
    </Fragment>
  );
};

export default EditRegionCharge;
