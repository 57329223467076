import React, { useEffect } from "react";
import { logOutAndRedirect } from "../../utils/helpers/authentication";
import { apiBaseUrl } from "../../constants/urlConstants";

const Logout = () => {
  useEffect(() => {
    logOutAndRedirect(`${apiBaseUrl()}/users/authentication/logout`);
  });

  return <div></div>;
};

export default Logout;
