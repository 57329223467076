import Types from "./types";

const updateBreadcrumbTitle = title => ({
  type: Types.UPDATE_BREADCRUMB_TITLE,
  title
});

const fetchBreadcrumbTitle = () => ({
  type: Types.FETCH_BREADCRUMB_TITLE
});

export { updateBreadcrumbTitle, fetchBreadcrumbTitle };
