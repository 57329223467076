import RegionChargesGrid from "./component";
import { updateBreadcrumbTitle } from "../../components/common/breadcrumbs/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchRegionChargesList } from "./actions";
import { fetchAllRegionsList } from "../region/actions";

const mapStateToProps = ({ regionChargesGrid: { data } }) => {
  const { page, detail: regionChargesList, totalPages, totalRecords } = data;
  return { page, regionChargesList, totalPages, totalRecords };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBreadcrumbTitleConnect: updateBreadcrumbTitle,
      fetchRegionChargesListConnect: fetchRegionChargesList,
      fetchRegionsListConnect: fetchAllRegionsList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegionChargesGrid);
