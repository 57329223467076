import { TAXATION_API_PATH } from "../constants/apiConstants";
import queryString from "querystring";

const {
  REGION_CHARGE_PATH,
  REGION_CHARGE_LIST_PATH,
  CESS_TAX_PATH,
  SERVICE_CHARGE_CALCULATION_PATH,
  REFUND_CHARGE_CALCULATION_PATH,
  UPDATE_REGION_CHARGE_PATH,
  ADD_REGION_CHARGE_PATH
} = TAXATION_API_PATH;

export default api => {
  const getRegionChargesList = params => {
    return api.get(`${REGION_CHARGE_LIST_PATH}?`, { params });
  };
  const getRegionCharges = params => {
    return api.get(`${REGION_CHARGE_PATH}?`, { params });
  };
  const addRegionCharge = params => {
    return api.post(ADD_REGION_CHARGE_PATH, params);
  };
  const updateRegionCharge = params => {
    return api.put(UPDATE_REGION_CHARGE_PATH, params);
  };
  const deleteRegionCharge = id => {
    return api.delete(`${REGION_CHARGE_PATH}/${id}`);
  };

  const getCessTaxList = params => {
    return api.get(`${CESS_TAX_PATH}`);
  };
  const getCessTax = id => {
    return api.get(`${CESS_TAX_PATH}/${id}`);
  };
  const createCessTax = params => {
    return api.post(CESS_TAX_PATH, params);
  };
  const updateCessTax = params => {
    return api.put(CESS_TAX_PATH, params);
  };
  const deleteCessTax = id => {
    return api.delete(`${CESS_TAX_PATH}/${id}`);
  };

  const calculateServiceCharge = params => {
    return api.post(SERVICE_CHARGE_CALCULATION_PATH, params);
  };

  const calculateRefundCharge = params => {
    return api.post(REFUND_CHARGE_CALCULATION_PATH, params);
  };

  return {
    getRegionChargesList,
    getRegionCharges,
    addRegionCharge,
    updateRegionCharge,
    deleteRegionCharge,
    getCessTaxList,
    getCessTax,
    createCessTax,
    updateCessTax,
    deleteCessTax,
    calculateServiceCharge,
    calculateRefundCharge
  };
};
