const STATUS_INTERNAL_SERVER_ERROR = 500;
const STATUS_BAD_GATEWAY = 502;
const STATUS_BAD_REQUEST = 400;
const STATUS_UNAUTHORIZED = 401;
const STATUS_OK = 200;
const STATUS_CREATED = 201;

export {
  STATUS_INTERNAL_SERVER_ERROR,
  STATUS_BAD_GATEWAY,
  STATUS_BAD_REQUEST,
  STATUS_UNAUTHORIZED,
  STATUS_OK,
  STATUS_CREATED
};
