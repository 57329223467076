import Types from "./types";
import { TaxationApi } from "../../services";

const fetchRegionChargesListInit = () => {
  return {
    type: Types.FETCH_REGION_CHARGE_LIST_INIT
  };
};

const fetchRegionChargesListSuccess = data => {
  return {
    type: Types.FETCH_REGION_CHARGE_LIST_SUCCESS,
    data
  };
};

const fetchRegionChargesFailure = () => {
  return {
    type: Types.FETCH_REGION_CHARGE_LIST_FAILURE
  };
};

const fetchRegionChargesList = params => dispatch => {
  dispatch(fetchRegionChargesListInit());
  return new Promise((resolve, reject) => {
    TaxationApi.getRegionChargesList(params)
      .then(response => {
        dispatch(fetchRegionChargesListSuccess(response.data.data));
        resolve(response.data);
      })
      .catch(error => {
        dispatch(fetchRegionChargesFailure());
        reject(error);
      });
  }).catch(error => {
    console.log("Error: ", error);
  });
};

export { fetchRegionChargesList };
