import taxationBaseApi from "./taxationBaseApi";
import omsBaseApi from "./omsBaseApi";
import transactionService from "./taxationService";
import regionService from "./regionService";

const TaxationApi = transactionService(taxationBaseApi);
const RegionApi = regionService(taxationBaseApi);
const OmsRegionApi = regionService(omsBaseApi);

export { TaxationApi, RegionApi, OmsRegionApi };
