import Types from "./types";
import { TaxationApi } from "../../services";

export const fetchRegionChargesInit = () => {
  return { type: Types.FETCH_REGION_CHARGES_INIT };
};

export const fetchRegionChargesSuccess = data => {
  return { type: Types.FETCH_REGION_CHARGES_SUCCESS, data };
};

export const fetchRegionChargesFailure = () => {
  return { type: Types.FETCH_REGION_CHARGES_FAILURE };
};

export const fetchRegionCharges = id => dispatch => {
  dispatch(fetchRegionChargesInit());
  return new Promise((resolve, reject) => {
    TaxationApi.getRegionCharges(id)
      .then(response => {
        dispatch(fetchRegionChargesSuccess(response.data.data));
        resolve(response.data);
      })
      .catch(error => {
        dispatch(fetchRegionChargesFailure());
        reject(error);
      });
  });
};
