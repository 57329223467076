import React, { useEffect, Fragment } from "react";

const Welcome = ({ updateBreadcrumbTitle }) => {
  useEffect(() => {
    updateBreadcrumbTitle("Welcome");
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col lg4 s12 m4">
          <h6>Welcome to Admin Panel v2.</h6>
        </div>
      </div>
      <div className="row">
        <div className="col lg12 s12 m12">
          <blockquote>
            We are happy to roll out significant improvements to the new
            dashboard. These changes make the dashaboard cleaner, simpler and
            easier to manage.
          </blockquote>
        </div>
      </div>
    </Fragment>
  );
};

export default Welcome;
