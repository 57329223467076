import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBreadcrumbTitle } from "../../components/common/breadcrumbs/actions";
import Welcome from "./component";

const mapStateToProps = title => {
  return title;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBreadcrumbTitle: updateBreadcrumbTitle
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
