import Types from "./types";
import { createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  data: [],
  isLoading: false
};

export const fetchRegionChargesInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, data: [] };
};

export const fetchRegionChargesSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, data, isLoading: false };
};

export const fetchRegionChargesError = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false, data: [] };
};

export const HANDLERS = {
  [Types.FETCH_REGION_CHARGES_INIT]: fetchRegionChargesInit,
  [Types.FETCH_REGION_CHARGES_SUCCESS]: fetchRegionChargesSuccess,
  [Types.FETCH_REGION_CHARGES_FAILURE]: fetchRegionChargesSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
