import { AUTHENTICATION_TOKEN_KEY } from "../../constants/constants";
import { apiBaseUrl } from "../../constants/urlConstants";
import { getCookie, deleteCookie } from "./cookie";
import { deleteItem } from "../../utils/helpers/localStorage";
import { REGION_LOCAL_STORAGE_KEY } from "../../constants/constants";

export function authenticate() {
  if (!isUserLoggedIn()) {
    window.location.href = apiBaseUrl();
  }
  return true;
}
export const isUserLoggedIn = () => {
  return getCookie(AUTHENTICATION_TOKEN_KEY) ? true : false;
};

export const getAuthenticationToken = () => {
  return getCookie(AUTHENTICATION_TOKEN_KEY);
};

export const logout = () => {
  deleteCookie(AUTHENTICATION_TOKEN_KEY);
  deleteItem(REGION_LOCAL_STORAGE_KEY);
};

export const logOutAndRedirect = redirectUrl => {
  logout();
  window.location.href = redirectUrl;
};
