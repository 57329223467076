import React, { Fragment, useEffect, useState } from "react";
import { ROUTE } from "../../constants/routeConstants";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getItem, setItem } from "../../utils/helpers/localStorage";
import {
  REGION_LOCAL_STORAGE_KEY,
  NOT_FOUND_REGION_ID
} from "../../constants/constants";
import _ from "underscore";

const RegionChargesGrid = ({
  page,
  regionChargesList,
  totalPages,
  totalRecords,
  updateBreadcrumbTitleConnect,
  fetchRegionChargesListConnect,
  fetchRegionsListConnect
}) => {
  const [currentPage, setCurrentPage] = useState(page ? page : 0);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(totalPages ? totalPages : 0);
  const [matchedRegionIds, setMatchedRegionIds] = useState(null);

  useEffect(() => {
    let regionChargesParamsObj = { page: currentPage, size: size };
    if (matchedRegionIds != "") {
      regionChargesParamsObj = {
        ...regionChargesParamsObj,
        region: matchedRegionIds
      };
    }
    //Fetch regions
    fetchRegionChargesListConnect(regionChargesParamsObj);
    updateBreadcrumbTitleConnect("Region Charges");
    setPaginationData();
  }, [currentPage]);

  const getRegionsData = () => {
    let regionLocalStorageData = getItem(REGION_LOCAL_STORAGE_KEY);
    regionLocalStorageData = regionLocalStorageData
      ? JSON.parse(regionLocalStorageData)
      : [];
    return regionLocalStorageData;
  };

  const setPaginationData = () => {
    setPageCount(pageCount);
  };

  const handlePageClick = data => {
    let selected = data.selected;
    setCurrentPage(selected);
  };

  let regions = getRegionsData();

  //Handle region search
  const handleRegionSearch = event => {
    const target = event.target;
    const value = target.value;
    if (!value) {
      fetchRegionChargesListConnect({ page: currentPage, size: size });
      return true;
    }
    fetchRegionChargesListConnect({
      page: 0,
      size: size,
      regions: value
    });
    setMatchedRegionIds(value);
  };

  let regionChargesListHtml = null;

  if (regionChargesList && regions) {
    regionChargesListHtml = regionChargesList.map(row => {
      return (
        <tr key={row.regionId}>
          <td>{row.regionId}</td>
          <td>{row ? row.regionName : ""}</td>
          <td>{row.variableCharge ? "Yes" : "No"}</td>
          <td>
            <Link
              to={`${ROUTE.TAXATION_REGION_CHARGES_DETAIL}/${row.regionId}`}
              className="waves-useEffect"
            >
              View/Edit
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col l3 s12 m3 right">
          <input
            placeholder="Search Region"
            id="search"
            type="text"
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleRegionSearch(e);
              }
            }}
          />
        </div>
        <div className="col s12 m12 l12">
          <table className="responsive-table highlight">
            <thead>
              <tr>
                <th>Region Id</th>
                <th>Region Name</th>
                <th>Variable Charges</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{regionChargesListHtml}</tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col s6 m6 l6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
        <div className="col s6 m6 l6">
          <ul className="pagination">
            {/* Showing {size} records out of {totalRecords} */}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default RegionChargesGrid;
