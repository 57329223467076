import React, { useEffect, useState, Fragment } from "react";
import Collapsible from "../common/collapsible";
import EditRegionCharge from "../editRegionCharge";
import _ from "underscore";
import { DEFAULT_CHARGES_REGION_ID } from "../../constants/constants";

const RegionChargeDetail = ({
  data,
  updateBreadcrumbTitleConnect,
  fetchRegionChargesConnect,
  match
}) => {
  const [
    defaultChargesCollapsibleData,
    setDefaultChargesCollapsibleData
  ] = useState([]);
  const [
    cutomizableChargesCollapsibleData,
    setCutomizableChargesCollapsibleData
  ] = useState([]);

  let { regionId } = match.params;

  useEffect(() => {
    initializeElements();
    updateBreadcrumbTitleConnect("Edit Service Charge");
    async function fetchRegionCharges() {
      await fetchRegionChargesConnect({
        regionId: [DEFAULT_CHARGES_REGION_ID, regionId].join(",")
      });
    }
    fetchRegionCharges();
  }, []);

  useEffect(() => {
    if (data && data[DEFAULT_CHARGES_REGION_ID]) {
      setDefaultChargesCollapsibleData(
        chargesCollapsibleData(data[DEFAULT_CHARGES_REGION_ID])
      );
    }
    if (data && data[regionId]) {
      setCutomizableChargesCollapsibleData(
        chargesCollapsibleData(data[regionId])
      );
    }
  }, [JSON.stringify(data)]);

  let defaultChargesCollapsibleHtml = "";
  let cutomizableChargesCollapsibleHtml = "";
  if (defaultChargesCollapsibleData) {
    defaultChargesCollapsibleHtml = (
      <Collapsible elements={defaultChargesCollapsibleData}></Collapsible>
    );
  }
  if (cutomizableChargesCollapsibleData) {
    cutomizableChargesCollapsibleHtml = (
      <Collapsible elements={cutomizableChargesCollapsibleData}></Collapsible>
    );
  }

  return (
    <Fragment>
      <div className="col l12 m12 s12">
        <h5>Default Charges</h5>
        {defaultChargesCollapsibleHtml}
        <h5>Customizable Charges</h5>
        {cutomizableChargesCollapsibleHtml}
      </div>
    </Fragment>
  );
};

const chargesCollapsibleData = regionDetail => {
  let parsedData = regionDetail.map(row => {
    return {
      key: row.chargeKey,
      headerTitle: row.name,
      headerClass:
        row.regionId !== DEFAULT_CHARGES_REGION_ID && row.isActive == true
          ? "green accent-1"
          : "",
      body: <EditRegionCharge chargeDetail={row}></EditRegionCharge>,
      icon: "chevron_right"
    };
  });
  return parsedData;
};

const initializeElements = () => {
  const M = window.M;

  let collapsibleElems = document.querySelectorAll(".collapsible");
  M.Collapsible.init(collapsibleElems, {});
};

export default RegionChargeDetail;
