import { STATUS_UNAUTHORIZED, STATUS_OK } from "../../constants/httpConstants";
import _ from "underscore";

export const getErrorMessage = errorObj => {
  let msg = null;
  if (errorObj.status == STATUS_UNAUTHORIZED) {
    msg = "Unauthorized Access. Please login!";
  } else if (errorObj && errorObj.status !== STATUS_OK) {
    if (errorObj.data.errors) {
      let errorsArray = _.pluck(errorObj.data.errors, "message");
      msg = _.uniq(errorsArray).join(",");
    }
    if (!msg && errorObj.data.message) {
      msg = errorObj.data.message;
    }
    if (!msg) {
      msg = "Something went wrong!";
    }
  }
  return msg;
};
