import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTE } from "../../../constants/routeConstants";
import BreadCrumbs from "../breadcrumbs";
import Welcome from "../../welcome";
import PageNotFound from "../../pageNotFound";
import RegionChargesGrid from "../../regionChargesGrid";
import RegionChargeDetail from "../../regionChargeDetail";
import Logout from "../../logout";
import AddRegionCharge from "../../addRegionCharge";

const AppRouter = () => {
  return (
    <Fragment>
      <div id="main">
        <div className="row">
          <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
          <BreadCrumbs />
          <div className="col s12">
            <div className="container">
              <div className="card">
                <div className="card-content">
                  <Switch>
                    <Route
                      exact
                      path={ROUTE.HOME}
                      render={props => <Welcome {...props}></Welcome>}
                    />
                    <Route
                      exact
                      path={ROUTE.TAXATION_REGION_CHARGES}
                      render={props => (
                        <RegionChargesGrid
                          {...props}
                          key={Math.random()}
                        ></RegionChargesGrid>
                      )}
                    />
                    <Route
                      exact
                      path={`${ROUTE.TAXATION_REGION_CHARGES_DETAIL}/:regionId`}
                      render={props => (
                        <RegionChargeDetail {...props}></RegionChargeDetail>
                      )}
                    />
                    <Route
                      exact
                      path={`${ROUTE.TAXATION_ADD_REGION_CHARGE}`}
                      render={props => (
                        <AddRegionCharge {...props}></AddRegionCharge>
                      )}
                    />
                    <Route
                      exact
                      path={`${ROUTE.LOGOUT}`}
                      render={props => <Logout {...props}></Logout>}
                    />
                    <Route
                      render={props => <PageNotFound {...props}></PageNotFound>}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppRouter;
