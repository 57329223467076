import React, { Fragment, useEffect } from "react";
import If from "react-if";

const Select = ({
  name,
  options,
  defaultValue,
  label,
  handleInputChange,
  fieldIdentifier = null,
  disabled = false,
  emptyOptionText = "Choose your option",
  required = false,
  searchable = false
}) => {
  useEffect(() => {
    initializeElements();
    if (searchable) {
      searchableSelect();
    }
  }, []);

  return (
    <Fragment>
      <select
        name={name}
        value={defaultValue}
        onChange={e => handleInputChange(e, fieldIdentifier)}
        disabled={disabled}
        required={required}
      >
        <If condition={!searchable}>
          <option value="">{emptyOptionText}</option>
        </If>

        {options.map(row => {
          return (
            <option key={row.key} value={row.key}>
              {row.value}
            </option>
          );
        })}
      </select>
      <label>{label}</label>
    </Fragment>
  );
};

const initializeElements = () => {
  const M = window.M;
  var selectElems = document.querySelectorAll("select");
  M.FormSelect.init(selectElems, {});
};

const searchableSelect = () => {
  document.querySelectorAll("select").forEach(elem => {
    const select = elem.M_FormSelect;
    const options = select.dropdownOptions.querySelectorAll(
      "li:not(.optgroup)"
    );

    // Add search box to dropdown
    const placeholderText = "Search Text";
    const searchBox = document.createElement("div");
    searchBox.style.padding = "6px 16px 0 16px";
    searchBox.innerHTML = `
            <input type="text" placeholder="${placeholderText}">
            </input>`;
    select.dropdownOptions.prepend(searchBox);

    // Function to filter dropdown options
    function filterOptions(event) {
      const searchText = event.target.value.toLowerCase();

      options.forEach(option => {
        const value = option.textContent.toLowerCase();
        const display = value.indexOf(searchText) === -1 ? "none" : "block";
        option.style.display = display;
      });

      select.dropdown.recalculateDimensions();
    }

    // Function to give keyboard focus to the search input field
    function focusSearchBox() {
      searchBox.firstElementChild.focus({
        preventScroll: true
      });
    }

    select.dropdown.options.autoFocus = false;

    if (window.matchMedia("(hover: hover) and (pointer: fine)").matches) {
      select.input.addEventListener("click", focusSearchBox);
      options.forEach(option => {
        option.addEventListener("click", focusSearchBox);
      });
    }
    searchBox.addEventListener("keyup", filterOptions);
  });
};

export default Select;
