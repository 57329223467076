import RegionChargeDetail from "./component";
import { updateBreadcrumbTitle } from "../common/breadcrumbs/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchRegionCharges } from "./actions";

const mapStateToProps = ({ regionCharges }) => {
  const { data } = regionCharges;
  return { data };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBreadcrumbTitleConnect: updateBreadcrumbTitle,
      fetchRegionChargesConnect: fetchRegionCharges
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegionChargeDetail);
