import EditRegionCharge from "./component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateRegionCharge } from "./actions";
import { fetchRegionCharges } from "../regionChargeDetail/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateRegionChargeConnect: updateRegionCharge,
      fetchRegionChargesConnect: fetchRegionCharges
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditRegionCharge);
