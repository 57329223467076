import {
  chargeAmountTypeConstants,
  chargeModeConstants,
  chargeTypeConstants
} from "../constants/constants";

export const getChargeAmountTypes = () => {
  return [
    { key: chargeAmountTypeConstants.FIX, value: "Fix" },
    { key: chargeAmountTypeConstants.PERCENTAGE, value: "Percentage" }
  ];
};

export const getChargeModes = () => {
  return [
    { key: chargeModeConstants.EXCLUSIVE, value: "Exclusive" },
    { key: chargeModeConstants.INCLUSIVE, value: "Inclusive" }
  ];
};

export const getchargeTypeConstants = () => {
  return [
    { key: chargeTypeConstants.SLAB, value: "Slab" },
    { key: chargeTypeConstants.FLAT, value: "Flat" }
  ];
};

export const getChargesList = () => {
  return [
    { key: "serviceCharge", value: "Service Charges" },
    { key: "lateStockInCharge", value: "Late Stock In Charges" },
    { key: "insuranceCharge", value: "Insurance Charges" },
    { key: "hypoRemovalCharge", value: "Hypo Removal Charges" },
    { key: "instantPaymentCharge", value: "Instant Payment Charges" },
    { key: "aadharSeedingCharge", value: "Aadhar eKYC Charges" },
    { key: "addressProofCharge", value: "Address Proof Charges" },
    { key: "interstateRtoCharge", value: "Interstate RTO Charges" },
    { key: "fuelRecoveryCharge", value: "Fuel Recovery Charges" },
    { key: "convenienceCharge", value: "Convenience Charges"},
    { key: "parking_charges", value: "Parking Charges"},
    { key: "towing_charges", value: "Towing Charges"},
    { key: "shield_charges", value: "Shield Charges"}
  ];
};
