import Types from "./types";
import { createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  data: [],
  isLoading: false
};

export const fetchRegionChargesListInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchRegionChargesListSuccess = (
  state = INITIAL_STATE,
  { data }
) => {
  return { ...state, data, isLoading: false };
};

export const fetchRegionChargesListError = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_REGION_CHARGE_LIST_INIT]: fetchRegionChargesListInit,
  [Types.FETCH_REGION_CHARGE_LIST_SUCCESS]: fetchRegionChargesListSuccess,
  [Types.FETCH_REGION_CHARGE_LIST_FAILURE]: fetchRegionChargesListError
};

export default createReducer(INITIAL_STATE, HANDLERS);
