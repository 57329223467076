import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../constants/routeConstants";
import { withRouter } from "react-router-dom";

const LeftSideBar = () => {
  useEffect(() => {
    initializeElements();
  }, []);

  return (
    <Fragment>
      <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square">
        <div className="brand-sidebar">
          <h1 className="logo-wrapper">
            <a className="brand-logo darken-1" href="index.html">
              <img src="https://static.cars24.com/cars24/images/cars-logo.png?v=1" />
            </a>
          </h1>
        </div>
        <ul
          className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
          id="slide-out"
          data-menu="menu-navigation"
          data-collapsible="menu-accordion"
        >
          <ul className="collapsible">
            <li className="bold">
              <Link to={ROUTE.HOME} className="waves-effect waves-cyan">
                <i className="material-icons">dashboard</i>
                Dashboard
              </Link>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">attach_money</i>Taxation
              </div>
              <div className="collapsible-body">
                <ul>
                  <li>
                    {/* //TODO::Change this to react router link */}
                    <a
                      href={ROUTE.TAXATION_REGION_CHARGES}
                      className="waves-effect"
                    >
                      <i className="material-icons">trending_flat</i>Region
                      Charges
                    </a>
                  </li>
                  <li>
                    {/* //TODO::Change this to react router link */}
                    <a
                      href={ROUTE.TAXATION_ADD_REGION_CHARGE}
                      className="waves-effect"
                    >
                      <i className="material-icons">trending_flat</i>Add Region
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </ul>
        <div className="navigation-background"></div>
      </aside>
    </Fragment>
  );
};

const initializeElements = () => {
  const M = window.M;

  let sideNavElems = document.querySelectorAll(".sidenav");
  M.Modal.init(sideNavElems, {});

  let collapsibleElems = document.querySelectorAll(".collapsible");
  M.Collapsible.init(collapsibleElems, {});
};

export default withRouter(LeftSideBar);
