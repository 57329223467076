import AddRegionCharge from "./component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAllRegionsList } from "../region/actions";
import { addRegionCharge } from "./actions";
import { updateBreadcrumbTitle } from "../../components/common/breadcrumbs/actions";

const mapStateToProps = ({ regions: { data } }) => {
  let regionsList = data && data.detail ? data.detail : [];
  return { regionsList };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addRegionChargeConnect: addRegionCharge,
      fetchAllRegionsListConnect: fetchAllRegionsList,
      updateBreadcrumbTitleConnect: updateBreadcrumbTitle
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRegionCharge);
