import Types from "./types";
import { TaxationApi } from "../../services";

export const addRegionChargeInit = () => {
  return { type: Types.ADD_REGION_CHARGE_INIT };
};

export const addRegionChargeSuccess = data => {
  return { type: Types.ADD_REGION_CHARGE_SUCCESS, data };
};

export const addRegionChargeFailure = () => {
  return { type: Types.ADD_REGION_CHARGE_FAILURE };
};

export const addRegionCharge = data => dispatch => {
  dispatch(addRegionChargeInit());
  return new Promise((resolve, reject) => {
    TaxationApi.addRegionCharge(data)
      .then(response => {
        dispatch(addRegionChargeSuccess(response));
        resolve(response.data);
      })
      .catch(error => {
        dispatch(addRegionChargeFailure());
        reject(error);
      });
  });
};
