import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "./component";

const mapStateToProps = ({ loadingReducer }) => {
  const { loading } = loadingReducer;
  return { loading };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
