import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBreadcrumbTitle } from "../../components/common/breadcrumbs/actions";
import PageNotFound from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBreadcrumbTitle: updateBreadcrumbTitle
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
