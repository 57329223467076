import React, { Fragment } from "react";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Link } from "react-router-dom";
import { ROUTE, TAXATION } from "../../../constants/routeConstants";

const routes = [
  { path: ROUTE.TAXATION_REGION_CHARGES_DETAIL, breadcrumb: null }, // null indicates that this link will be skipped in Breadcrumbs
  { path: `${TAXATION}`, breadcrumb: null }
];

const BreadCrumbs = ({ title, breadcrumbs }) => {
  let breadCrumbsListHtml = breadcrumbs.map(({ match, breadcrumb }) => (
    <li key={match.url} className="breadcrumb-item">
      <Link to={match.url}>{breadcrumb}</Link>
    </li>
  ));

  return (
    <Fragment>
      <div className="breadcrumbs-dark pb-0" id="breadcrumbs-wrapper">
        <div className="container">
          <div className="row">
            <div className="col s10 m6 l6">
              <h5 className="breadcrumbs-title mt-0 mb-0">{title}</h5>
              <ol className="breadcrumbs mb-0">{breadCrumbsListHtml}</ol>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withBreadcrumbs(routes)(BreadCrumbs);
