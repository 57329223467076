export const chargeAmountTypeConstants = {
  FIX: "FIX",
  PERCENTAGE: "PERCENTAGE"
};

export const chargeModeConstants = {
  EXCLUSIVE: "EXCLUSIVE",
  INCLUSIVE: "INCLUSIVE"
};

export const chargeTypeConstants = {
  SLAB: "SLAB",
  FLAT: "FLAT"
};

export const REGION_LOCAL_STORAGE_KEY = "regions";
export const AUTHENTICATION_TOKEN_KEY = "auth_token";
export const NOT_FOUND_REGION_ID = -1;
export const DEFAULT_CHARGES_REGION_ID = 0;
export const ACTUAL_CHARGES_ROOT_REGION_ID = 1;
