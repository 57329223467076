import axios from "axios";
import { apiBaseUrl } from "../constants/urlConstants";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/helpers/errorHandler";
import { logOutAndRedirect } from "../utils/helpers/authentication";
import { STATUS_UNAUTHORIZED } from "../constants/httpConstants";

const DEBUG = process.env.APP_ENV === "LOCAL";

const BASE_API_URL = apiBaseUrl();
/* Create axios instance */
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 * Intercepts request
 */
api.interceptors.request.use(
  config => {
    /** Log console for dev */
    if (DEBUG) {
      console.info("✉️ ", config);
    }
    return config;
  },
  error => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use(
  response => {
    if (DEBUG) {
      console.info("📩 ", response);
    }
    return response;
  },
  error => {
    //captureException(error);
    if (error.response && error.response.data) {
      if (DEBUG) {
        console.error("Error: ", error.response.data);
      }
      toast.error(getErrorMessage(error.response));
      if (error.response.status === STATUS_UNAUTHORIZED) {
        logOutAndRedirect(apiBaseUrl());
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error("📩 ", error);
    }
    return Promise.reject(error.message);
  }
);

export default api;
