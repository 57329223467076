import Types from "./types";
import { createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  list: {},
  isLoading: false
};

export const fetchRegionsListInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchRegionsListSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, ...data, isLoading: false };
};

export const fetchRegionsListError = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_REGIONS_LIST_INIT]: fetchRegionsListInit,
  [Types.FETCH_REGIONS_LIST_SUCCESS]: fetchRegionsListSuccess,
  [Types.FETCH_REGIONS_LIST_FAILURE]: fetchRegionsListError
};

export default createReducer(INITIAL_STATE, HANDLERS);
