export const TAXATION_API_PATH = {
  REGION_CHARGE_PATH: "/charge/config/detail",
  REGION_CHARGE_LIST_PATH: "/charge/config/service",
  CESS_TAX_PATH: "/cessTax",
  SERVICE_CHARGE_CALCULATION_PATH: "/service/charge/calculate",
  REFUND_CHARGE_CALCULATION_PATH: "/service/refund/refund",
  REGIONS_LIST: "/charge/config/regions",
  UPDATE_REGION_CHARGE_PATH: "/charge/config/update",
  ADD_REGION_CHARGE_PATH: "/charge/config/initCharge"
};

export const OMS_API_PATH = {
  ALL_REGIONS_LIST: "/open-api/get-regions?type=all&format=list"
};
