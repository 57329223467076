import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className="page-footer footer footer-static footer-dark gradient-45deg-indigo-purple gradient-shadow navbar-border navbar-shadow">
        <div className="footer-copyright">
          <div className="container">
            <span>
              &copy; 2020{" "}
              <a
                href="https://cars24.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                CARS24
              </a>{" "}
              All rights reserved.
            </span>
            <span className="right hide-on-small-only">
              Design and Developed by{" "}
              <a
                href="https://cars24.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                CARS24
              </a>
            </span>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
