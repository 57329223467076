import { resettableReducer } from "reduxsauce";
import { combineReducers } from "redux";
import breadCrumbsReducer from "../components/common/breadcrumbs/reducers";
import regionChargesGridReducer from "../components/regionChargesGrid/reducers";
import addRegionChargesReducer from "../components/addRegionCharge/reducers";
import regionChargesReducer from "../components/regionChargeDetail/reducers";
import regionsReducer from "../components/region/reducers";
import loadingReducer from "redux-loading-middleware/loadingReducer";

const resettable = resettableReducer("RESET");

const rootReducer = combineReducers({
  loadingReducer: resettable(loadingReducer),
  breadCrumbsDetail: resettable(breadCrumbsReducer),
  regionChargesGrid: resettable(regionChargesGridReducer),
  regionCharges: resettable(regionChargesReducer),
  regions: resettable(regionsReducer),
  addRegionCharge: resettable(addRegionChargesReducer)
});

export { rootReducer };
