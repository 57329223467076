import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumbs from "./component";
import { fetchBreadcrumbTitle } from "./actions";

const mapStateToProps = ({ breadCrumbsDetail }) => {
  const { title } = breadCrumbsDetail;
  return { title };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBreadcrumbTitle: fetchBreadcrumbTitle
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
