import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
  title: ""
};

export const fetchBreadcrumbTitle = (state = INITIAL_STATE, { title }) => {
  return { ...state, title };
};

export const updateBreadcrumbTitle = (state = INITIAL_STATE, { title }) => {
  return { ...state, title };
};

export const HANDLERS = {
  [Types.UPDATE_BREADCRUMB_TITLE]: updateBreadcrumbTitle,
  [Types.FETCH_BREADCRUMB_TITLE]: fetchBreadcrumbTitle
};

export default createReducer(INITIAL_STATE, HANDLERS);
