export const getItem = key => {
  return window.localStorage.getItem(key);
};

/**
 * Set local storage
 * @param {key} key
 * @param {data} data
 */
export const setItem = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
  return true;
};

/**
 * Delete local storage item
 * @param {key} key
 */
export const deleteItem = key => {
  window.localStorage.removeItem(key);
  return true;
};
