import { TAXATION_API_PATH, OMS_API_PATH } from "../constants/apiConstants";

const { REGIONS_LIST } = TAXATION_API_PATH;
const { ALL_REGIONS_LIST } = OMS_API_PATH;

export default api => {
  const getRegionsList = params => {
    return api.get(REGIONS_LIST);
  };
  const getAllRegionsList = params => {
    return api.get(ALL_REGIONS_LIST);
  };

  return {
    getRegionsList,
    getAllRegionsList
  };
};
